/* skeleton css */
.anime-card-skeleton {
  width: 100%;
  max-width: 300px;
  margin: 10px;
}

.card-skeleton {
  width: 100%;
  height: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* skeleton css end */

.anime-search {
  padding: 150px 20px 0 20px;
  margin-bottom: 50px;
}

.anime-search p {
  color: white;
  text-align: center;
}

.search-results {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.anime-card {
  text-decoration: none;
  color: inherit;
  width: 100%;
  max-width: 300px;
  margin: 10px;
}

.search-card-container :hover {
  background-color: #494f55;
}

.card {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.card img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.card-details {
  padding: 10px;
}

.card-details h2 {
  margin-top: 0;
}

.card-details p {
  margin: 5px 0;
}

/* Media query for smaller screens */

@media (max-width: 768px) {
  .anime-search {
    padding-top: 100px;
  }

  .anime-card {
    margin: 10px 0;
  }

  .card {
    margin: 10px 0;
  }
}
