.top10-animes-background {
  padding: 20px;
  background-color: #041e42;
}

.top10-animes-container {
  text-align: center;
  margin-top: 20px;
}

.top10-anime-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.top10-anime-card {
  width: 200px;
  margin: 10px;
  cursor: pointer;
  background-color: #04152d;

  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.top10-anime-card .top10-image {
  height: auto;
  width: 150px;
  border-radius: 8px;
  margin-bottom: 10px;
  transform: perspective(600px) translateZ(0); 
}

.top10-anime-card .top10-image:hover {
  animation: hoverEffect 0.6s ease forwards;
}

.top10-anime-card .top10-image:not(:hover) {
  animation: hoverEffectOut 0.6s ease forwards;
}

@keyframes hoverEffect {
  0% {
    transform: perspective(600px) translateZ(0) rotateX(0) rotateY(0);
  }
  100% {
    transform: perspective(600px) translateZ(30px) rotateX(5deg) rotateY(5deg);
  }
}

@keyframes hoverEffectOut {
  0% {
    transform: perspective(600px) translateZ(30px) rotateX(5deg) rotateY(5deg);
  }
  100% {
    transform: perspective(600px) translateZ(0) rotateX(0) rotateY(0);
  }
}

.top10-anime-card p {
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

a {
  text-decoration: none;
  color: white;
}
