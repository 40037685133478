.psychological-container {
  padding: 130px 0px 0 0px;
}

.psychological-container h1 {
  text-align: center;
  margin-bottom: 30px;
  white-space: nowrap;
}


