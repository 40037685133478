.playback-section {
  flex: 1;
}

.playback-section h4,
h2 {
  color: white;
  text-align: center;
}

.playback-section h4 {
  margin-bottom: 20px;
}

.playback-section .video-player {
  width: 640px;
  height: 360px;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}
.playback-section .episode-loading {
  color: white;
  text-align: center;
  background-color: #df0b0b;
}

.playback-section .next-episode {
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #ff7c00;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.playback-section .next-episode span {
  cursor: pointer;
}

/* ////......Media query......//// */

@media (max-width: 500px) {
  .playback-section .video-player {
    margin: 20px 0 20px 0;
    width: 100%;
    height: 160px;
    display: block;
  }

  .next-episode {
    margin-bottom: 80px;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .playback-section .video-player {
    margin: 20px 0 20px 0;
    width: 100%;
    height: 360px;
    display: block;
  }

  .next-episode {
    margin-bottom: 80px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .playback-section .video-player {
    margin: 20px 0 20px 0;
    width: 100%;
    height: 360px;
    display: block;
  }

  .next-episode {
    margin-bottom: 80px;
  }
}

@media (orientation: landscape) and (max-height: 500px) {
  .playback-section .video-player {
    margin: 20px 0 20px 0;
    width: 100%;
    height: 360px;
    display: block;
  }

  .next-episode {
    margin-bottom: 80px;
  }
}