.episodes-list-container {
  margin: 70px;
  flex: 1;
  text-align: center;
  color: white;
  margin-bottom: 50px;
  max-height: 70vh;
}

.episodes-list {
  max-height: calc(70vh - 50px);
  overflow-y: auto;
}

.episodes-list::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.episodes-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  background-color: #CCCCCC;
}

.episodes-list::-webkit-scrollbar-thumb {
  background-color: #FFF;
  background-image: -webkit-linear-gradient(90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 25%,
      transparent 100%,
      rgba(0, 0, 0, 1) 75%,
      transparent);
}

.episodes-list-container h2 {
  background-color: #ff7c00;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
}

.episodes-list ul {
  margin-top: 10px;
}

.episodes-list ul li {
  border-bottom: 1px dashed #df0b0b;
  padding-bottom: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.episodes-list ul li:hover {
  background-color: #ff7c00;
}

.episodes-list ul li .episode-list-loading {
  color: white;
  text-align: center;
  background-color: #df0b0b;
}

/* Media query */

@media (max-width: 768px) {
  .episodes-list-container {
    margin: 10px;
    margin-bottom: 60px;
  }

  .episodes-list ul li {
    padding: 8px;
    font-size: 14px;
  }
}