.background-poster {
  width: 100%;
  position: relative;
  height: 80vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.background-poster::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 21, 45, 0.6);
  background: linear-gradient(180deg, rgba(60, 82, 80, 0.315) 0%, #04152d 79.17%);
}

.poster-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
  padding: 0;
}

.poster-content .text {
  font-size: 43px;
  margin-top: 40px;
  margin-bottom: 30px;
  font-weight: 550;
  white-space: nowrap;
}

.poster-content .search-box {
  position: relative;
  text-align: center;
  justify-content: center;
  z-index: 2;
}

.poster-content .search-box input[type="text"] {
  width: 800px;
  height: 45px;
  border: none;
  outline: none;
  background: #f2f2f2;
  border-radius: 20px;
  color: #333;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.poster-content .search-box button {
  cursor: pointer;
  width: 37px;
  height: 37px;
  border-radius: 51%;
  border: none;
  position: absolute;
  top: 51%;
  right: 57px;
  transform: translateY(-50%);
  background: green;
  color: #fff;
}

.trendinganime-wrapper {
  position: absolute;
  margin-top: 650px;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 100%;
  z-index: 1;
}

.trendinganime-wrapper h1 {
  font-size: 30px;
  color: white;
}

/* Media Queries for Responsive Design */

@media (max-width: 500px) {
  .background-poster {
    height: 50vh;
  }

  .poster-content .text {
    font-size: 16px;
    margin-top: 10px;
  }

  .poster-content .search-box input[type="text"] {
    width: 300px;
    height: 35px;
    font-size: 12px;
  }

  .poster-content .search-box button {
    width: 30px;
    height: 30px;
    right: 10px;
  }

  .trendinganime-wrapper {
    margin-top: 530px;
  }

  .trendinganime-wrapper h1 {
    font-size: 20px;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .background-poster {
    height: 50vh;
  }

  .poster-content .text {
    font-size: 24px;
    margin-top: 90px;
  }

  .poster-content .search-box input[type="text"] {
    width: 500px;
  }

  .poster-content .search-box button {
    width: 35px;
    height: 35px;
    right: 70px;
  }

  .trendinganime-wrapper {
    margin-top: 580px;
  }

  .trendinganime-wrapper h1 {
    font-size: 25px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .background-poster {
    height: 50vh;
  }

  .poster-content .text {
    font-size: 35px;
    margin-top: 90px;
  }

  .poster-content .search-box input[type="text"] {
    width: 550px;
  }

  .poster-content .search-box button {
    width: 35px;
    height: 35px;
    right: 99px;
  }

  .trendinganime-wrapper h1 {
    font-size: 28px;
  }
}