@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

footer{
  position: relative;
  background: #020c1b;
  width: 100%;
  bottom: 0;
  left: 0;
}

footer::before{
  content: '';
  position: absolute;
  left: 0;
  top: 100px;
  height: 1px;
  width: 100%;
  background: #AFAFB6;
}

footer .content{
  max-width: 1250px;
  margin: auto;
  padding: 30px 40px 40px 40px;
}

footer .content .top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.content .top .logo-details{
  color: #fff;
  font-size: 30px;
}

.content .top .logo-details i {
    color: blue;
}

 .content .top .media-icons{
  display: flex;
}

.content .top .media-icons a{
  height: 40px;
  width: 40px;
  margin: 0 8px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 17px;
  text-decoration: none;
  transition: all 0.4s ease;
}

footer .content .link-boxes{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

footer .content .link-boxes .box{
  width: calc(100% / 5 - 10px);
}

.content .link-boxes .box .link_name{
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
  position: relative;
}

.link-boxes .box .link_name::before{
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
  width: 35px;
  background: #fff;
}

.content .link-boxes .box li{
  margin: 14px 0;
  list-style: none;
}

.content .link-boxes .box li a{
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.4s ease
}

.content .link-boxes .box li a:hover{
  opacity: 1;
  text-decoration: underline;
}

.content .link-boxes .input-box{
  margin-right: 55px;
}
/* 
.link-boxes .input-box input{
  height: 40px;
  width: calc(100% + 55px);
  outline: none;
  border: 2px solid #AFAFB6;
  background: #140B5C;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  color: #fff;
  margin-top: 5px;
}

.link-boxes .input-box input::placeholder{
  color: #AFAFB6;
  font-size: 16px;
}

.link-boxes .input-box input[type="button"]{
  background: #fff;
  color: #140B5C;
  border: none;
  font-size: 18px;
  font-weight: 500;
  margin: 4px 0;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.4s ease;
}

.input-box input[type="button"]:hover{
  opacity: 1;
} */

footer .bottom-details{
  width: 100%;
  background: #000000;
}

footer .bottom-details .bottom_text{
  max-width: 1250px;
  margin: auto;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
}

.bottom-details .bottom_text span,
.bottom-details .bottom_text a{
  font-size: 15px;
  font-weight: 300;
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
}

.bottom-details .bottom_text a:hover{
  opacity: 1;
  text-decoration: underline;
}

.bottom-details .bottom_text a{
  margin-right: 10px;
}

@media (max-width: 900px) {
  footer .content .link-boxes{
    flex-wrap: wrap;
  }

  footer .content .link-boxes .input-box{
    width: 40%;
    margin-top: 10px;
  }
}

@media (max-width: 700px){
  footer{
    position: relative;
  }

  .content .top .logo-details{
    font-size: 26px;
  }

  .content .top .media-icons a{
    height: 35px;
    width: 35px;
    font-size: 14px;
    line-height: 35px;
  }

  footer .content .link-boxes .box{
    width: calc(100% / 3 - 10px);
  }

  footer .content .link-boxes .input-box{
    width: 60%;
  }

  .bottom-details .bottom_text span,
  .bottom-details .bottom_text a{
    font-size: 12px;
  }
}

@media (max-width: 520px){
  footer::before{
    top: 145px;
  }

  footer .content .top{
    flex-direction: column;
  }

  .content .top .media-icons{
    margin-top: 16px;
  }

  footer .content .link-boxes .box{
    width: calc(100% / 2 - 10px);
  }

  footer .content .link-boxes .input-box{
    width: 100%;
  }
}

/* glowing button */

:root {
  --glow-color: hsl(186 100% 69%);
}

footer .input-box .glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.65em 0.7em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
  background: none;
  perspective: 2em;
  font-family: "Raleway", sans-serif;
  font-size: 1.1em;
  font-weight: 700;
  letter-spacing: 0.6em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color), 0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color), 0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color), 0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

footer .input-box .glowing-btn .glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

footer .input-box .glowing-btn .faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

footer .input-box .glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

footer .input-box .glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

footer .input-box .glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

footer .input-box .glowing-btn:hover .glowing-txt {
  animation: none;
}

footer .input-box .glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

footer .input-box .glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

footer .input-box .glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 0.1;
  }

  4% {
    opacity: 0.5;
  }

  19% {
    opacity: 0.5;
  }

  21% {
    opacity: 0.1;
  }

  23% {
    opacity: 1;
  }

  80% {
    opacity: 0.5;
  }

  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }

  20% {
    opacity: 1;
  }

  25% {
    opacity: 0.3;
  }

  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }

  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }

  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

/* glowing button end */


/* Footer social links */

footer .social :hover {
  color: royalblue;
}

footer .social i {
  font-size: 1.4rem;
  margin: 2px 8px;
  cursor: pointer;
}

footer .social:before {
  position: absolute;
  content: "";
  width: 90%;
  height: 1px;
  background: rgba(255, 255, 255, 0.192);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* Footer social links end */
