h1 {
  color: white;
  padding: 20px;
}

.home-container {
  margin-top: 300px;
}

.spotlightanime-container {
  margin: 30px 0 30px 0;
}

.topairinganime-container {
  margin: 20px 0 30px 0;
}

.topupcominganime-container {
  margin: 10px 0 30px 0;
}

.latestepisode-container {
  margin: 10px 0 30px 0;
}

/* Media query */

@media (max-width: 500px) {
  h1 {
    font-size: 20px;
  }
}

@media (max-width: 769px) {
  .home-container {
    margin-top: 330px;
  }
}