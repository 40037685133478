#button {
    display: inline-block;
    background-color: transparent; 
    border: 2px solid #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 25px;
    right: 80px;
    transition: background-color .3s, opacity .5s, visibility .5s, border-color .3s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
}

#button::after {
    content: "\f077";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 1.4em;
    line-height: 40px;
    color: #fff;
}

#button:hover {
    cursor: pointer;
    background-color: #333;
}

#button:active {
    background-color: #555;
}

#button.show {
    opacity: 1;
    visibility: visible;
}

/* media query */

@media (max-width: 1000px) {
    #button {
        z-index: 50;
    }
}