body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  background-color: #CCCCCC;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  background-color: #FFF;
  background-image: -webkit-linear-gradient(90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 25%,
      transparent 100%,
      rgba(0, 0, 0, 1) 75%,
      transparent)
}

body {
  background-color: #04152d;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  overflow-x: hidden;
}