/* Simple loading bar */
.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

.loading-bar .progress {
  width: 100%;
  height: 100%;
  background-color: blue;
  animation: loading 2s linear;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

/* End Simple loading bar */

/* About Us section styles */

.about-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
  align-items: center;
  min-height: 100vh;
  margin: 0 auto;
  max-width: 600px;
  padding: 120px 0;
}

.about-us h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-us p {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: rgb(202, 183, 183);
}

@media screen and (max-width: 768px) {
  .about-us {
    padding: 100px 20px;
    max-width: 90%;
  }

  .about-us h2 {
    font-size: 2rem;
  }

  .about-us p {
    font-size: 1rem;
    margin-bottom: 30px;
  }
}
