* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-size: cover;
  height: 100vh;
  background-position: center;
}

header {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 99;
  box-shadow: 0 0 10px transparent;
  background: rgba(0, 0, 0, 0.5);
}

#chk1 {
  display: none;
}

i {
  color: #fff;
  cursor: pointer;
}

header .logo {
  flex: 1;
  color: #fff;
  margin-left: 50px;
  font-size: 15px;
}

header .name-container {
  text-align: center;
  position: relative;
}

header .anime {
  font-size: 16px;
  color: white;
  position: relative;
  top: 7px;
  left: 55%;
  transform: translateX(-50%);
  opacity: 0.8; 
  transition: opacity 0.4s ease; 
}

header .anime:hover {
  opacity: 2;
}

header .name a {
  font-size: 34px;
  font-weight: bold;
  color: cyan;
}

header .search-box {
  flex: 1;
  position: relative;
}

.search-box input {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  background: #f2f2f2;
  border-radius: 30px;
  color: gray;
  font-size: 16px;
  padding-left: 5px;
  padding-right: 40px;
  text-align: center;
}

.search-box button {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  transform: scale(0.9);
  background: green;
  color: #fff;
}

header ul {
  flex: 2;
  display: flex;
  justify-content: space-evenly;
}

header ul li {
  list-style: none;
}

header ul li a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.8; 
  transition: opacity 0.4s ease; 
}

header ul li a:hover {
  border-bottom: 2px solid #fff;
  opacity: 1;
}

header .menu {
  font-size: 2.5em;
  display: none;
}

@media (max-width: 1000px) {
  .search-box button {
    position: absolute;
  }

  header ul {
    position: fixed;
    top: 70px;
    right: -100%;
    background: rgba(0, 0, 0, 0.5);
    height: calc(100vh - 70px);
    width: 60%;
    flex-direction: column;
    align-items: center;
    transition: right 0.5s linear;
  }

  header .menu {
    display: block;
    width: 100px;
    text-align: center;
  }

  #chk1:checked~ul {
    right: 0;
  }

  header .name-container {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}