/* skeleton css */
.carousel-skeleton {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.carousel-item-skeleton {
  width: 100%;
  height: 300px;
  background-color: #041e42;
  border-radius: 8px;
}

/* skeleton css end */

.carousel-buttons {
  position: relative;
  margin-bottom: 20px;
}

.next-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.image {
  width: 300px;
  height: 300px;
  border-radius: 10px;
  animation: none;
}

.image:hover {
  animation: float 0.5s ease forwards;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

.paragraph {
  text-align: center;
  color: white;
}

.paragraph a {
  color: white;
  text-decoration: none;
}

.slick-slider {
  overflow: hidden;
}

.slick-list {
  margin: 0 0 0 25px;
}

.slick-slide {
  padding: 0 0;
}

.slick-prev,
.slick-next {
  z-index: 1;
}

/* Adjust prev and next button styles as needed */
.slick-prev:before,
.slick-next:before {
  color: white;
}

.slick-prev {
  left: 0; /* Adjust the position of the prev button */
}

.slick-next {
  right: 0; /* Adjust the position of the next button */
}

/* ////......Media query......//// */

@media (max-width: 500px) {
  .slick-slide {
    padding: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .paragraph {
    text-align: center;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .slick-slide {
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paragraph {
    text-align: center;
  }

  .slick-next {
    right: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .slick-slide {
    padding: 0 33px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .paragraph {
    text-align: center;
  }

  .slick-list {
    margin: 0 20px 0 40px;
  }

  .slick-next {
    right: 60px;
  }
}

@media (orientation: landscape) and (max-height: 500px) {
  .slick-slide {
    padding: 0 33px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .paragraph {
    text-align: center;
  }

  .slick-list {
    margin: 0 20px 0 40px;
  }

  .slick-next {
    right: 60px;
  }
}
